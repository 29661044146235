import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Link } from "gatsby"

import Obfuscate from "react-obfuscate"

const Legal = () => (
  <Layout>
    <SEO title="Aviso Legal" />
    <section style={{ paddingTop: "16rem" }}>
      <div className="row m-10-hor">
        <div className="col-md-12 text-center">
          <h1>Aviso legal</h1>
        </div>
      </div>
    </section>
    <section className="legal">
      <div className="row m-10-hor">
        <article className="col-md-7 mx-auto legal">
          <h2>Identificación y Titularidad</h2>
          <p>
            En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio,
            de Servicios de la Sociedad de la Información y Comercio
            Electrónico, el Titular expone sus datos identificativos.
          </p>
          <ul>
            <li>
              <b>Titular:</b>
              Cerezo Rosa S.L.
            </li>
            <li>
              <b>NIF:</b> B01962083
            </li>
            <li>
              <b>Domicilio:</b> Calle Marroquina 55, Madrid.
            </li>
            <li>
              <b>Correo electrónico:</b>{" "}
              <Obfuscate email="ruedaenrosa@cerezorosa.com" />
            </li>

            <li>
              <b>Sitio Web:</b> <Link to="/">https://cerezorosa.com</Link>
            </li>
          </ul>

          <h2>Finalidad</h2>
          <p>
            La finalidad del sitio Web{" "}
            <Link to="/">https://cerezorosa.com</Link> es Alquiler de material
            de producción.
          </p>

          <h2>Condiciones de Uso</h2>
          <p>
            La utilización del sitio Web te otorga la condición de Usuario, e
            implica la aceptación completa de todas las cláusulas y condiciones
            de uso incluidas en las páginas:
          </p>
          <ul>
            <li>
              <Link to="/aviso-legal/">Aviso Legal</Link>
            </li>
            <li>
              <Link to="/politica-privacidad/">Política de Privacidad</Link>
            </li>
          </ul>

          <p>
            Si no estás conforme con todas y cada una de estas cláusulas y
            condiciones te abstendrás de utilizar este sitio Web.
          </p>

          <p>
            El acceso a este sitio Web no supone, en modo alguno, el inicio de
            una relación comercial con Cerezo Rosa S.L.
          </p>

          <p>
            A través de este sitio Web, el Titular te facilita el acceso y la
            utilización de diversos contenidos que el Titular o sus
            colaboradores han publicado por medio de Internet.
          </p>

          <p>
            A tal efecto, te obligas y comprometes a NO utilizar cualquiera de
            los contenidos del sitio Web con fines o efectos ilícitos,
            prohibidos en este Aviso Legal o por la legislación vigente, lesivos
            de los derechos e intereses de terceros, o que de cualquier forma
            puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la
            normal utilización de los contenidos, los equipos informáticos o los
            documentos, archivos y toda clase de contenidos almacenados en
            cualquier equipo informático propios o contratados por Cerezo Rosa
            S.L., de otros usuarios o de cualquier usuario de Internet.
          </p>

          <h2>Medidas de seguridad</h2>

          <p>
            Los datos personales que facilites al Titular pueden ser almacenados
            en bases de datos automatizadas o no, cuya titularidad corresponde
            en exclusiva a Cerezo Rosa S.L., que asume todas las medidas de
            índole técnica, organizativa y de seguridad que garantizan la
            confidencialidad, integridad y calidad de la información contenida
            en las mismas de acuerdo con lo establecido en la normativa vigente
            en protección de datos.
          </p>
          <p>
            No obstante, debes ser consciente de que las medidas de seguridad de
            los sistemas informáticos en Internet no son enteramente fiables y
            que, por tanto el Titular no puede garantizar la inexistencia de
            virus u otros elementos que puedan producir alteraciones en los
            sistemas informáticos (software y hardware) del Usuario o en sus
            documentos electrónicos y ficheros contenidos en los mismos aunque
            el Titular pone todos los medios necesarios y toma las medidas de
            seguridad oportunas para evitar la presencia de estos elementos
            dañinos.
          </p>

          <h2>Tratamiento de Datos Personales</h2>
          <p>
            Puedes consultar toda la información relativa al tratamiento de
            datos personales que recoge el Titular en la página de{" "}
            <Link href="/politica-privacidad/">Política de Privacidad</Link>.
          </p>

          <h2>Contenidos</h2>
          <p>
            El Titular ha obtenido la información, el contenido multimedia y los
            materiales incluidos en el sitio Web de fuentes que considera
            fiables, pero, si bien ha tomado todas las medidas razonables para
            asegurar que la información contenida es correcta, el Titular no
            garantiza que sea exacta, completa o actualizada. Cerezo Rosa S.L.
            declina expresamente cualquier responsabilidad por error u omisión
            en la información contenida en las páginas de este sitio Web.
          </p>

          <p>
            Queda prohibido transmitir o enviar a través del sitio Web cualquier
            contenido ilegal o ilícito, virus informáticos, o mensajes que, en
            general, afecten o violen derechos de&nbsp;el Titular o de terceros.
          </p>

          <p>
            Los contenidos de <Link to="/">https://cerezorosa.com</Link> tienen
            únicamente una finalidad informativa y bajo ninguna circunstancia
            deben usarse ni considerarse como oferta de venta, solicitud de una
            oferta de compra ni recomendación para realizar cualquier otra
            operación, salvo que así se indique expresamente.
          </p>

          <p>
            Cerezo Rosa S.L. se reserva el derecho a modificar, suspender,
            cancelar o restringir el contenido de{" "}
            <Link href="/">https://cerezorosa.com</Link>, los vínculos o la
            información obtenida a través del sitio Web, sin necesidad de previo
            aviso.
          </p>

          <p>
            Cerezo Rosa S.L.&nbsp;no es responsable de los daños y perjuicios
            que pudieran derivarse de la utilización de la información del sitio
            Web.
          </p>

          <h2>Enlaces a otros sitios Web</h2>
          <p>
            El Titular puede proporcionarte acceso a sitios Web de terceros
            mediante enlaces con la finalidad exclusiva de informarte sobre la
            existencia de otras fuentes de información en Internet en las que
            podrás ampliar los datos ofrecidos en el sitio Web.
          </p>

          <p>
            Estos enlaces a otros sitios Web no suponen en ningún caso una
            sugerencia o recomendación para que visites las páginas web de
            destino, que están fuera del control del Titular, por lo que Cerezo
            Rosa S.L. no es responsable del contenido de los sitios web
            vinculados ni del resultado que obtengas al seguir los enlaces.
          </p>

          <p>
            Asimismo, Cerezo Rosa S.L. no responde de los links o enlaces
            ubicados en los sitios web vinculados a los que te proporciona
            acceso.
          </p>

          <p>
            El establecimiento del enlace no implica en ningún caso la
            existencia de relaciones entre Cerezo Rosa S.L. y el propietario del
            sitio en el que se establezca el enlace, ni la aceptación o
            aprobación por parte del Titular de sus contenidos o servicios.
          </p>

          <p>
            Si accedes a un sitio Web externo desde un enlace que encuentres en{" "}
            <Link to="/">https://cerezorosa.com</Link> deberás leer la propia
            política de privacidad del otro sitio web que puede ser diferente de
            la de este sitio Web.
          </p>

          <h2>Propiedad intelectual e industrial</h2>
          <p>Todos los derechos están reservados.</p>
          <p>
            Todo acceso a este sitio Web está sujeto a las siguientes
            condiciones: la reproducción, almacenaje permanente y la difusión de
            los contenidos o cualquier otro uso que tenga finalidad pública o
            comercial queda expresamente prohibida sin el consentimiento previo
            expreso y por escrito de Cerezo Rosa S.L.
          </p>

          <h2>Limitación de responsabilidad</h2>
          <p>
            Cerezo Rosa S.L. declina cualquier responsabilidad en caso de que
            existan interrupciones o un mal funcionamiento de los Servicios o
            contenidos ofrecidos en Internet, cualquiera que sea su causa.
            Asimismo, el Titular no se hace responsable por caídas de la red,
            pérdidas de negocio a consecuencia de dichas caídas, suspensiones
            temporales de fluido eléctrico o cualquier otro tipo de daño
            indirecto que te pueda ser causado por causas ajenas a el Titular.
          </p>
          <p>
            Antes de tomar decisiones y/o acciones con base a la información
            incluida en el sitio Web, el Titular te recomienda comprobar y
            contrastar la información recibida con otras fuentes.
          </p>

          <h2>Jurisdicción</h2>
          <p>
            Este Aviso Legal se rige íntegramente por la legislación española.
          </p>

          <h2>Contacto</h2>
          <p>
            En caso de que tengas cualquier duda acerca de estas Condiciones
            legales o quieras realizar cualquier comentario sobre este sitio
            Web, puedes enviar un mensaje de correo electrónico a la dirección{" "}
            <Obfuscate email="ruedaenrosa@cerezorosa.com" />
          </p>

          <h2>Resolución de litigios en línea</h2>

          <p>
            <a
              href="//ec.europa.eu/consumers/odr/"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              Enlace a la plataforma de la Comisión Europea
            </a>{" "}
            de acuerdo con el reglamento sobre resolución de litigios en línea
            en materia de consumo.
          </p>
        </article>
      </div>
    </section>
  </Layout>
)

export default Legal
